import '../app.js';
import Dropzone from 'dropzone';

require('dropzone/dist/min/dropzone.min.css')

const protocol = window.location.protocol;
const host = window.location.host;
const base_url = protocol + "//" + host + "/" + window.location.pathname.split('/')[1];

const addListeners = () => {
    $("#mosaic").click(() => {
        $(".mosaic-wrapper").fadeIn();
        $('body').addClass('mosaic-open');
    })

    $(".mosaic-wrapper-close").click(() => {
        $(".mosaic-wrapper").fadeOut();
        $('body').removeClass('mosaic-open');
    })

    $('.btn.drop').click(() => {
        $('.drop-zone').click();
    })

    $('.popup').click(() => {
        $('.popup').fadeOut();
    })

    $(".drop-zone-title").click(() => {
        $('#file_upload').click();
    })

    $('#report').click(() => {
        $('.report-modal').fadeIn();
    })

    $('.report-modal').click(() => {
        $('.report-modal').fadeOut();
    })

    $('.report-modal-body').click((e) => {
        e.stopPropagation();
    })

    $('.report-modal-body .glyphicon-remove').click(() => {
        $('.report-modal').fadeOut();
    })

    $('#report_form').submit((e) => {
        e.preventDefault();
        submitReport();
    })

    $('#url_form').submit((e) => {
        e.preventDefault();
        submitImage();
    })
}

const submitImage = () => {
    let imageUrl = $('input[name="url"]').val();
    $('.loading-page').removeClass('hidden');
    $('.main-content').addClass('hidden');
    $.ajax({
        url: base_url + "/vision/submit",
        method: 'POST',
        data: { url: imageUrl },
        success: function (response) {
            response = JSON.parse(response);
            if (!response.errors.length) {
                window.location.href = base_url + "/vision/show/" + response.uniqid;
            } else {
                $('.main-content').html(`
                <div class="col-md-2"></div>
                <div class="col-md-8 upload-wrapper">
                    <div class="loader-wrapper">
                        <p>${response.errors}</p>
                        <p><button class="btn btn-info" onclick="location.reload()">Vissza a főoldalra</button></p>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="col-md-2"></div>
            `)
            }
        },
        error: function () {
            $('.main-content').html(`
                <div class="col-md-2"></div>
                <div class="col-md-8 upload-wrapper">
                    <div class="loader-wrapper">
                        <p>Valami elromlott...</p>
                        <p><button class="btn btn-info" onclick="location.reload()">Vissza a főoldalra</button></p>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="col-md-2"></div>
            `)
        }
    })
}

const submitReport = () => {
    let uid = window.location.href.split('/').pop();
    let name = $('[name="name"]').val();
    let email = $('[name="email"]').val();
    let message = $('[name="message"]').val();
    if (name && email && message && grecaptcha.getResponse().length !== 0) {
        $('.submit .missing-field').html('');
        $.ajax({
            url: base_url + '/vision/report',
            data: {
                name: name,
                email: email,
                message: message,
                uniqid: uid,
                captcha: grecaptcha.getResponse()
            },
            method: "POST",
            success: function (response) {
                response = JSON.parse(response);
                if (response.success) {
                    $('.report-modal-content').html('<h2>Köszönjük!</h2><p>Jelentését rögzítettük, kollégáink kivizsgálják az esetleges sértő tartalmat! Hamarosan jelentkezünk a megadott e-mail címen!</p>');
                } else {
                    if (response.errorCodes === "Already reported!") {
                        $('.report-modal-content').html('<h2><br></h2><p>Ezt a képet már jelentették!</p>');
                    } else {
                        $('.submit .missing-field').html('Valami elromlott, kérjük próbáld újra!');
                    }
                }
            },
            error: function() {
                $('.submit .missing-field').html('Valami elromlott, kérjük próbáld újra!');
            }
        })
    } else {
        $('.submit .missing-field').html('Hiányzó mező!');
    }
}

const loadScripts = () => {
    $.getScript('https://platform.twitter.com/widgets.js');
    $.getScript('https://apis.google.com/js/platform.js');
    $.getScript('https://www.google.com/recaptcha/api.js');
}

const showPageLoader = {
    count: 0,
    go: function () {
        this.count += 1;
        if (this.count === Array.from($('.result-percentage')).length) {
            loadScripts();
        }
    }
}

const animateValue = (index, start, end, duration) => {
    let range = end - start;
    let current = start;
    let increment = end > start ? 1 : -1;
    let stepTime = Math.abs(Math.floor(duration / range));
    let obj = $('.result-percentage').eq(index);
    let timer = setInterval(function () {
        current += increment;
        obj.html(current + "%");
        if (current === parseInt(end)) {
            showPageLoader.go();
            clearInterval(timer);
        }
    }, stepTime);
}

$(document).ready(() => {
    addListeners();
    if ($('.dominant-colors').html()) {
        let divs = Array.from($('.dominant-colors'));
        let width = 100 / divs.length;
        divs.forEach(function (item) {
            $(item).css('width', width + "%");
        })
    }
    if ($('.mosaic-wrapper').html()) {
        mosaic();
    }
    if ($('.img-wrapper').html()) {
        faceHighlight();
        setTimeout(function () {
            $('.confidence-line').removeClass('start');
            let resultDivs = Array.from($('.result-percentage'));
            resultDivs.forEach((div, index) => {
                let end = $(div).attr('data-p');
                animateValue(index, 0, end, 1000);
            })
        }, 1000)
        let nameConf = $('.confidence-bars').attr('data-conf');
        let classToApply;
        if (nameConf >= 30 && nameConf < 60) {
            classToApply = "half";
        } else if (nameConf >= 60 && nameConf < 90) {
            classToApply = "quarterto";
        } else if (nameConf >= 90) {
            classToApply = "full";
        } else {
            classToApply = "quarter"
        }
        $('.confidence-bars').addClass(classToApply);
    }
    if ($('#dz').html()) {
        let dz = new Dropzone("#dz");
        dz.on("success", function (file, response) {
            response = JSON.parse(response);
            if (!response.errors.length) {
                window.location.href = base_url + "/vision/show/" + response.uniqid;
            } else {
                $('.main-content').html(`
                <div class="col-md-2"></div>
                <div class="col-md-8 upload-wrapper">
                    <div class="loader-wrapper">
                        <p>${response.errors}</p>
                        <p><button class="btn btn-info" onclick="location.reload()">Vissza a főoldalra</button></p>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="col-md-2"></div>
            `)
            }
        });
        dz.on("error", function (file, error, xhr) {
            if (xhr) {
                $('.main-content').html(`
	                    <div class="col-md-2"></div>
	                    <div class="col-md-8 upload-wrapper">
	                        <div class="loader-wrapper">
	                            <p>Valami elromlott...</p>
	                            <p><button class="btn btn-info" onclick="location.reload()">Vissza a főoldalra</button></p>
	                        </div>
	                        <div class="clearfix"></div>
	                    </div>
	                    <div class="col-md-2"></div>
	            `)
            }
        });
        dz.on("sending", function (file, xhr, formData) {
            console.log('sending', file, formData);
        });
        dz.on("processing", function (file) {
            console.log('processing', file);
        });
    }
})

$(window).resize(function(){
    if ($('.img-wrapper').html()) {
        faceHighlight();
    }
})

const mosaic = () => {
    let mosaicItems = Array.from($('.mosaic-item'));
    mosaicItems.forEach((item, index) => {
        let num = Math.floor(index % 4);
        $(item).appendTo('.mosaic-quarter:eq(' + num + ')');
    })
    let quarters = Array.from($('.mosaic-quarter'));
    quarters = shuffle(quarters);
    quarters.forEach((item) => {
        $(item).appendTo('.mosaic-wrapper');
    })
}

const faceHighlight = () => {
    let faces = Array.from($('.face-highlight'));
    let positions = faces.map(item => $(item).attr('data-position').split(','));
    let src = $(".img-wrapper img").attr('src');
    let displayPositions;
    let img = new Image();
    img.onload = function () {
        let originalWidth = this.width;
        let originalHeight = this.height;

        displayPositions = positions.map((pos) => {
            return pos.map((p, index) => {
                if (index === 0 || index === 2) {
                    return parseFloat(p) / originalWidth * 100;
                } else {
                    return parseFloat(p) / originalHeight * 100;
                }
            });
        })
        displayPositions.forEach((pos, index) => {
            $('.face-highlight').eq(index).css({"left": pos[0] + "%"});
            $('.face-highlight').eq(index).css({"top": pos[1] + "%"});
            $('.face-highlight').eq(index).css({"width": pos[2] + "%"});
            $('.face-highlight').eq(index).css({"height": pos[3] + "%"});
            $('[data-key=' + index + ']').css({"left": pos[0] + "%"});
            $('[data-key=' + index + ']').css({"top": (pos[1] - 4) + "%"});
        })
    }
    img.src = src;
}


Dropzone.autoDiscover = false;
Dropzone.options.dz = {
    paramName: "file",
    maxFilesize: 4,
    dictDefaultMessage: "",
    clickable: true,
    dictFileTooBig: "Túl nagy fájl ({{filesize}}Mb). Maximum fájl méret: {{maxFilesize}}Mb.",
    createImageThumbnails: false,
    previewTemplate: `<div class="dz-preview dz-file-preview">
                        <span data-dz-errormessage>
                        </span>
                    </div>`,
    acceptedFiles: "image/*",
    dictInvalidFileType: "Csak képfájlokat lehet feltölteni!",
    accept: function (file, done) {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
            if (this.width < 50 || this.width > 3200 || this.height < 50 || this.height > 3200) {
                $('.dz-error').empty();
                done('A kép mérete minimum 50 x 50, maximum 3200 x 3200 pixel lehet!');
            } else {
                $('.dz-error').empty();
                done();
                $('.loading-page').removeClass('hidden');
                $('.main-content').addClass('hidden');
            }
        };
        img.src = _URL.createObjectURL(file);
    }
};

const shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}
